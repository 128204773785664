import React from "react"
import "./OfkAtHome.scss"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import { graphql, navigate } from "gatsby"
import { ListGroup, ListGroupItem } from "reactstrap"

const OfkAtHome = ({ data }) => {
  return (
    <Layout>
      <div className="OfkAtHome">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OFK At Home"
          caption="Bring fitness to kids from the house"
        />
        <div className="container content">
          <p>New School year, new normal.</p>
          <p>
            We at OCR Academy understand that going back to school right now may
            not be ideal, but this is not ideal times. Either you have been
            doing home school for your child’s entire life, or you are in a
            position where you can keep your children home and see how this
            whole thing plays out for the first semester. Either way, we got
            your phys-ed class covered.
          </p>
          <p>
            Our Obstacle Fit Kids class is a perfect fit for your child and we
            will be offering the classes during the day. The class will follow a
            curriculum that we find best suits children’s physical and emotional
            needs. We are social creatures, we thrive in social settings, and
            depriving kids of social interactions in our eyes is detrimental to
            their growth. Obviously with what’s going on in the world these are
            strange times, but keeping kids isolated is not the answer, and
            neither is large class rooms. We know that some kids seem to prefer
            to be alone, or read as appose to playing with other kids. We know
            that some kids have trouble with social cues, they talk to much or
            talk to little, other issues may be:
          </p>
          <ul>
            <li>
              Some kids have a hard time picking up on social cues or following
              social rules.
            </li>
            <li>
              Trouble with social skills makes it hard to connect with others.
            </li>
            <li>
              Struggling socially can make kids feel isolated, but there are
              ways to help.
            </li>
          </ul>
          <p>
            The value that we feel that we can add to your child’s life is the
            luxury to be among peers their own age bonding while overcoming
            physical obstacles, and in doing so can bring people closer
            together. We want to be able to do this for your child but in a safe
            way. Our Pandemic procedures for our classes can be found here; but
            to simplify we have placed the following procedures in place:
          </p>
          <ul>
            <li>
              Your child will be placed in a group of kids, this group will not
              change for the entire semester. Each group will be placed on
              certain days, so if we have a situation where someone in that
              group may be infected, it will not affect the entire program, just
              that group.
            </li>

            <li>
              Groups will be able to train together 5 days a week at the
              following time slots:
              <ul>
                <li>10:00am-11:15am 7-13 years old</li>

                <li>1:00pm-2:15pm 7-13 years old</li>
              </ul>
            </li>

            <li>
              Each class will have a maximum of 10 kids per class, so that means
              1-10 ratio for kid to instructor ratio.
            </li>

            <li>
              You do not have to come to the gym 5 days a week, you can come
              either 1,3,or 5 days a week. You will pick your days and times and
              that’s your days and times for the entire semester.
            </li>

            <li>
              Our program is designed in stages, and after each stage, the
              children will be asked to sanitize hands and work station.
            </li>

            <li>
              1 parent per child in the facility, spectators must wear a mask,
              and maintain social distance. As you may remember our facility is
              small and between the two tables we have limited viewing spots for
              spectators. While weather is warm you can view from the loading
              dock as well.
            </li>
          </ul>
          <p>
            The class will have a grading system and will come with a report
            card where our instructors will be able to address area’s of concern
            and praise strengths.
          </p>
          <h2>What is OFK?</h2>
          <p>
            ObstacleFit Kids is a strength and conditioning program designed to
            effectively teach the ObstacleFit methodology to children aged 7-13
            years old through age appropriate instruction and cueing. In a group
            setting kids participate in fun and engaging workouts that deliver
            measurable results. This prepares them not only to be well rounded
            athletes but also awesome people as well. *Kids 14 and older can
            join our adult classes.
          </p>
          <h2>What Makes OFK Different?</h2>
          <p>
            OFK workouts consist of constantly varied, functional movements that
            deliver a fitness that is varied, inclusive, and totally awesome
            (VITA) and are scalable for any participants at any level. We base
            success on effort rather than comparison. These kids are training
            for life, not a small, sport specific skill set.
          </p>
          <h2>Why choose OFK at OCRA?</h2>
          <p>
            Our coaches have had background checks and this is renewed annually.
            Your coach has studied how to teach ObstacleFit to children using
            age appropriate methods and have years of experience working with
            youth under their belt.
          </p>
          <h2>Pricing and Information</h2>
          <p>
            You will be paying for each semester and based on how many days you
            come will determine the price:
          </p>
          <h3>September to December 31 st (18 weeks)</h3>
          <ul>
            <li>1 Day a week - $240 plus taxes per child</li>
            <li>3 Days a week - $400 plus taxes per child</li>
            <li>5 days a week - $480 plus taxes per child</li>
            <li>No sibling discounts, no refunds. Credit only.</li>
          </ul>
          <h3>January 1st -June 24 th (25 weeks)</h3>
          <ul>
            <li>1 Day a week - $360 plus taxes per child</li>
            <li>3 Days a week - $600 plus taxes per child</li>
            <li>5 days a week - $720 plus taxes per child</li>
            <li>No sibling discounts, no refunds. Credit only</li>
          </ul>
          <p>Monthly payment plans are available.</p>
          <p>
            To Register your child please email us info@painistemporary.net with
            the title “Home School” and one of our awesome staff will be happy
            to assist.
          </p>
          <p>
            We look forward to welcoming your child into our program and we
            appreciate your trust in allowing us to make a positive difference
            in the lives of your children. Lets work together to overcome life’s
            current challenges one obstacle at a time.
          </p>
          <p>
            When you register for this program each child be tested on the below
            criteria once they enroll, and again before the end of the program.
            In between we will have classes that are designed to help strengthen
            your child so they can perform better at the end of the semester.
            This provides a goal for the child to work towards and hopefully see
            and feel actual results that can be applied to their everyday lives.
            Once enrolled they will receive a Report Card that will grade kids
            on the following criteria’s:
          </p>
          <h2>Test 1:</h2>
          <p>
            The baseline test is a standard test that we believe tests a child’s
            overall performance. This tests Cardio, Stamina, and strength. The
            goal for this Baseline test is to slowly start to add weights for
            the squats so it becomes a total package. We will be testing this 3x
            in a school year and results will be presented on report card.
          </p>
          <h3>Baseline Test: 12 min time cap</h3>
          <ul>
            <li>500 METER ROW</li>
            <li>40 AIR SQUATS</li>
            <li>30 SITUPS</li>
            <li>20 PUSHUPS</li>
            <li>10 PULLUPS</li>
            <li>1 Row of walls</li>
          </ul>
          <h3>Scoring</h3>
          <ul>
            <li>6-7 mins 10 points</li>
            <li>7-8 mins 9 points</li>
            <li>8-9 mins 8 points</li>
            <li>9-10 mins 7 points</li>
            <li>10-11 mins 6 points</li>
            <li>11-12 mins 5 points</li>
          </ul>
          <p>
            If an obstacle cannot be completed, this will be reflected on the
            report card and time will be added.
          </p>
          <p>
            Example: Completed everything except the pull ups in a time of 7:00
            mins, we will add .30 to clock and the athletes total time will be
            7:30 so 9 points will be awarded.
          </p>
          <h2>Test 2</h2>
          <p>
            The O’Neill Fitness Test is designed to give a simple and reliable
            test of aerobic fitness.
          </p>
          <p>
            After about 10 minutes of familiarization with the Concept2 Indoor
            Rower, the test can be carried out to get an indication of baseline
            aerobic fitness by simply comparing the distance covered in four
            minutes on the chart. Further regular tests will indicate progress
            and are suitable for people of all ages and gender.
          </p>
          <p>
            <strong>Test Protocol</strong>
          </p>
          <ol>
            <li>
              Set the monitor on the Concept2 Indoor Rower for four minutes.
            </li>
            <li>Row for four minutes (wind resistance set to ‘3.’)</li>
            <li>
              Look for your age and weight category in the left hand column.
            </li>
            <li>
              Find your distance covered and check your condition from the row
              at the top.
            </li>
          </ol>
          <table>
            <thead>
              <tr>
                <th>Juniors</th>
                <th>Excellent</th>
                <th>Good</th>
                <th>Above Average</th>
                <th>Average</th>
                <th>Below Average</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Women J12</td>
                <td>886</td>
                <td>846</td>
                <td>766</td>
                <td>685</td>
                <td>605</td>
              </tr>
              <tr>
                <td>Women J13</td>
                <td>956</td>
                <td>916</td>
                <td>835</td>
                <td>755</td>
                <td>675</td>
              </tr>
              <tr>
                <td>Women J14</td>
                <td>999</td>
                <td>955</td>
                <td>885</td>
                <td>795</td>
                <td>725</td>
              </tr>
              <tr>
                <td>Women J15</td>
                <td>1042</td>
                <td>1001</td>
                <td>921</td>
                <td>841</td>
                <td>761</td>
              </tr>
              <tr>
                <td>Women J16</td>
                <td>1074</td>
                <td>1034</td>
                <td>954</td>
                <td>874</td>
                <td>793</td>
              </tr>
              <tr>
                <td>Women J17</td>
                <td>1109</td>
                <td>1069</td>
                <td>988</td>
                <td>908</td>
                <td>828</td>
              </tr>
            </tbody>
          </table>
          <h2>Test 3</h2>
          <p>
            Stamina – the ability of body systems to utilize, store, process,
            and deliver energy.
          </p>
          <p>
            <strong>Test: Tabata Squat and Push-up</strong>
          </p>
          <p>
            For twenty seconds do as many reps of the assigned exercise as you
            can – then rest 10 seconds. Repeat this seven more times for a total
            of 8 intervals, 4 minutes total exercise. The score is the least
            number of reps for any of the eight intervals.
          </p>
          <p>
            Start with a bodyweight squat. The athlete’s thighs must reach
            parallel, as defined in the ‘strength’ category. Arms may either
            travel in front of the torso or stay on the hips, but must not make
            contact with the floor or the thigh.
          </p>
          <p>
            Following 8 rounds of the squat, the athlete has only their normal
            10- second break to set up for the push up.
          </p>
          <p>
            Scoring: take the lowest number of reps achieved from the 8 sets of
            squats, and add it to the lowest number of reps achieved from the 8
            sets of push ups.
          </p>
           <p>Example(using 3 rounds only)</p> 
          <table>
            <thead>
              <tr>
                <th>Round</th>
                <th>SQ</th>
                <th>PU</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1    </td>
                <td>18</td>
                <td>15</td>
              </tr>
              <tr>
                <td>2    </td>
                <td>17</td>
                <td>16</td>
              </tr>
              <tr>
                <td>3    </td>
                <td>19</td>
                <td>14</td>
              </tr>
            </tbody>
          </table>
           
          <p>
            The lowest number of reps achieved is 17 in the SQ, and 14 in the
            PU. Total of 31 reps.
          </p>
          <p>Score Chart:</p>
          <table>
            <tbody>
              <tr>
                <td>&gt;45</td>
                <td>&gt;40</td>
                <td>10</td>
              </tr>
              <tr>
                <td>40-45</td>
                <td>35-39</td>
                <td>9</td>
              </tr>
              <tr>
                <td>35-39</td>
                <td>30-34</td>
                <td>8</td>
              </tr>
              <tr>
                <td>30-34</td>
                <td>25-29</td>
                <td>7</td>
              </tr>
              <tr>
                <td>25-29</td>
                <td>20-24</td>
                <td>6</td>
              </tr>
              <tr>
                <td>20-24</td>
                <td>15-19</td>
                <td>5</td>
              </tr>
              <tr>
                <td>15-19</td>
                <td>10-14</td>
                <td>4</td>
              </tr>
              <tr>
                <td>10-14</td>
                <td>5-9</td>
                <td>3</td>
              </tr>
              <tr>
                <td>5-9</td>
                <td>1-4</td>
                <td>2</td>
              </tr>
              <tr>
                <td>1-4</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>MALE(reps)</td>
                <td>FEMALE(reps)</td> <td></td>
              </tr>
            </tbody>
          </table>
          <h2>Test 4:</h2>
          <p>Grip Test</p>
          <p>
            Purpose of test is to determine ones ability to hold entire body
            weight for prolonged period of time. Time limit of 5 mins
          </p>
          <p>Scoring:</p>
          <table>
            <tbody>
              <tr>
                <td>5 mins</td>
                <td>10 points</td>
              </tr>
              <tr>
                <td>4:30 - 5 mins</td>
                <td>9 points</td>
              </tr>
              <tr>
                <td>4 - 4:30 mins</td>
                <td>8 points</td>
              </tr>
              <tr>
                <td>3:30 - 4 mins</td>
                <td>7 points</td>
              </tr>
              <tr>
                <td>3 - 3:30 mins</td>
                <td>6 points</td>
              </tr>
              <tr>
                <td>2:30 - 3 mins</td>
                <td>5 points</td>
              </tr>
              <tr>
                <td>2 - 2:30 mins</td>
                <td>4 points</td>
              </tr>
              <tr>
                <td>1:30 - 2 mins</td>
                <td>3 points</td>
              </tr>
              <tr>
                <td>1 - 1:30 mins</td>
                <td>2 points</td>
              </tr>
              <tr>
                <td>:30 - 1:00 mins</td>
                <td>1 point</td>
              </tr>
            </tbody>
          </table>
          <h2>Test 5</h2>
          <p>
            Nutrition test: Learn how to read a label on food, learn the
            difference between calories and nutrients. Learn that not all
            calories are equal. The goal for this program is to teach the kids
            that you may be able to obtain all the calories to fill your “quota”
            for the day, but you might be deficient in the nutrients. For
            example: A Timbit is 99 calories, but an Apple is 95 calories. One
            has significantly more nutrients then the other.
          </p>
          <p>
            Learn what are the three macronutrients are and the benefits they
            posses.
          </p>
          <p>What are good sources, and what are examples of bad sources.</p>
          <p>
            Good source – Home made locally sourced hamburger
            <br />
            Bad choice – Hamburger at McDonalds.
          </p>
          <p>
            We know this subject can be controversial and we want to let the
            parents know that we will not emphasize too much on making eating
            out at a fast food restaurant as a bad thing as we do enjoy the
            occasional fast food, but we want kids to understand that this
            cannot be an everyday thing.
          </p>
          <p>
            We both know that the kids are not buying the groceries, and the
            kids are not packing their own lunches, so we hope that the families
            involved can really play an active roll in this. By no way are we
            going to tell you what to feed your kid as each child has very
            unique needs and we know that every household is different, but if
            we can get everyone on whole foods and away from overly processed
            foods, that would be an ideal situation.
          </p>
          <p>
            We do have a holistic nutritionist who is helping us with this
            program, and the goal is to Educate not discriminate. If you are
            running out of ideas and would like to chat to our Nutritionist to
            discuss some healthy options, please contact Karine Vaillancourt RHN
            for more details
          </p>
          <p>
            The test will be to identify the three Macronutrients and what are
            they good for.
          </p>
          <p>What are the three macronutrients?</p>
          <p>
            Macronutrients are required by the body in large amounts on a daily
            basis. They include carbohydrates, proteins and fats. A balanced
            meal typically includes a combination of all three.
          </p>
          <p>
            1) CARBOHYDRATES provide fuel for the body and brain. It’s crucial
            to eat the right kind at the right time. A meal of only simple
            carbohydrates (carbs) such as white bread, white pasta, fruit and
            sweets (the typical kiddie diet) will break down in the body very
            quickly once digested. This can lead to a spike in blood sugar that
            will suddenly drop. Complex carbs, however, take longer to digest.
            Therefore, it’s best to eat simple carbs along with complex carbs,
            protein and/or a quality fat that will slow digestion and balance
            blood sugar. Stick to whole food carbohydrates that are packed with
            nutrients, such as sliced apples instead of apple juice, oatmeal
            instead of sugary cereals and roasted potatoes instead of potato
            chips. And remember to serve fruit after they’ve eaten the rest of
            their meal so that they don’t fill up on just simple carbs.
          </p>
          <p>
            SOURCES:
            <br />
            Complex Carbohydrates: most vegetables, whole grains, whole grain
            products, milk (because it contains natural sugars)
          </p>
          <p>
            Simple Carbohydrates: foods with naturally occurring sugars such as
            honey, fruit and some vegetables, as well as processed, sugary
            products like candy bars
          </p>
          <p>
            2) PROTEIN repairs tissues and is an important building block for
            muscle, skin, blood and cells. It’s important to eat protein in the
            morning because it balances blood sugar, which affects our physical
            health, moods and behavior. Since children are still growing, it’s
            crucial for them to start the day with a protein-dense breakfast and
            then eat structured snacks and meals with small amounts of protein
            throughout the day.
          </p>
          <p>
            SOURCES:
            <br />
            Animal Proteins: beef, turkey, pork, chicken, fish, eggs, dairy
            products Vegetarian &amp; Dairy-Free Proteins: beans, nuts, seeds,
            nut butters, soy (tofu, tempeh, edamame)
          </p>
          <p>
            3) FATS are a major source of energy, protect our organs and help us
            feel satisfied for many hours. Fats have gotten a bad rap; however,
            high-quality fats from reputable sources can actually prevent
            disease. Small amounts of fats from plants such as avocados, nuts
            and coconuts can be protective. Omega-3 fatty acids, found in some
            animals, also prevent disease and can be found in mackerel,
            sardines, wild salmon (farm-raised fish don’t contain them), flax
            seeds and walnuts.
          </p>
          <p>
            SOURCES:
            <br />
            Monounsaturated Fats: olive oil, many nuts, avocados Polyunsaturated
            Fats: fish, peanuts, sesame seeds, safflower, canola Saturated
            Fats: butter, heavy cream, fatty meats, coconut, palm oil Trans
            Fats: margarine/partially hydrogenated oils are harmful trans fats
            and should be avoided
          </p>
          <h2>Test 6:</h2>
          <p>The 40 Yard Dash</p>
          <p>
            purpose: The aim of this test is to determine acceleration, and also
            a reliable indicator of speed, agility and quickness.
          </p>
          <p>
            equipment required: measuring tape or marked
            track, stopwatch or timing gates, cone markers, flat and
            unobstructed grass, track, or turf surface of at least 60 yards.
          </p>
          <p>
            Description / procedure: The test involves running a single maximum
            sprint over 40 yards, with the time recorded. A thorough warm up
            should be given, including some practice starts and accelerations.
            Start from a comfortable stationary 3-point stance position, a
            position that is most familiar to you and that you think will yield
            the best time. The front foot must be on or behind the starting
            line. This starting position should be held for 3 seconds prior to
            starting, you may lean across the starting line, and no rocking
            movements are allowed. The tester should provide hints to maximizing
            speed and encouragement to continue running hard past the finish
            line.
          </p>
           <p>40 yard Sprint Scores (general guidelines)</p>
          <table>
            <tbody>
              <tr>
                <td>College Footballers</td>
                <td>4.6 – 4.9 secs</td>
              </tr>
              <tr>
                <td>High School Footballers</td>
                <td>4.9 – 5.6 secs</td>
              </tr>
              <tr>
                <td>Recreational College athletes (male)</td>
                <td>~5.0 secs</td>
              </tr>
              <tr>
                <td>Recreational College athletes (female)</td>
                <td>~5.8 secs</td>
              </tr>
            </tbody>
          </table>
           
          <p>
            <strong>results:</strong> Two trials are allowed, and the best time
            is recorded to the nearest 2 decimal places. The timing starts from
            the first movement (if using a stopwatch) or when the timing system
            is triggered, and finishes when the chest crosses the finish line
            and/or the finishing timing gate is triggered. targetpopulation:
            football and other sports in which speed over that distance is
            important
          </p>
          <p>comments: 40 yards is 36.58 meters.</p>
          <table>
            <thead>
              <tr>
                <th>Speed</th>
                <th>40yd Dash</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt;4.0</td>
                <td>4.1-4.5</td>
                <td>10</td>
              </tr>
              <tr>
                <td>4.1-4.5</td>
                <td>4.6-5.0</td>
                <td>9</td>
              </tr>
              <tr>
                <td>4.6-5.0</td>
                <td>5.1-5.5</td>
                <td>8</td>
              </tr>
              <tr>
                <td>5.1-5.5</td>
                <td>5.6-6.0</td>
                <td>7</td>
              </tr>
              <tr>
                <td>5.6-6.0</td>
                <td>6.1-6.5</td>
                <td>6</td>
              </tr>
              <tr>
                <td>6.1-6.5</td>
                <td>6.6-7.0</td>
                <td>5</td>
              </tr>
              <tr>
                <td>6.6-7.0</td>
                <td>7.1-7.5</td>
                <td>4</td>
              </tr>
              <tr>
                <td>7.1-7.5</td>
                <td>7.6-8.0</td>
                <td>3</td>
              </tr>
              <tr>
                <td>7.6-8.0</td>
                <td>8.1-9.0</td>
                <td>2</td>
              </tr>
              <tr>
                <td>8.1-9.0</td>
                <td>9.1-10.0</td>
                <td>1</td>
              </tr>
              <tr>
                <td>MALE(s)</td>
                <td>FEMALE(s)</td> <td></td>
              </tr>
            </tbody>
          </table>
          <h2>Test 7</h2>
          <p>
            Coordination – the ability to combine several distinct movement
            patterns into a single movement.
          </p>
          <p>Test: Skipping.</p>
          <p>
            The client performs a simple two-foot skip with a jump rope (leather
            or vinyl, not cotton.) They’re timed. Any stoppage of the rope
            results in a stop of the clock. Clients are allowed two attempts.
          </p>
          <p>CAT Score:</p>
          <table>
            <thead>
              <tr>
                <th>Coordination</th>
                <th>Skipping</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt;10mins</td> <td></td>
                <td>10</td>
              </tr>
              <tr>
                <td>9-10mins</td> <td></td>
                <td>9</td>
              </tr>
              <tr>
                <td>8-9mins</td> <td></td>
                <td>8</td>
              </tr>
              <tr>
                <td>7-8mins</td> <td></td>
                <td>7</td>
              </tr>
              <tr>
                <td>6-7mins</td> <td></td>
                <td>6</td>
              </tr>
              <tr>
                <td>5-6mins</td> <td></td>
                <td>5</td>
              </tr>
              <tr>
                <td>4-5mins</td> <td></td>
                <td>4</td>
              </tr>
              <tr>
                <td>3-4mins</td> <td></td>
                <td>3</td>
              </tr>
              <tr>
                <td>2-3mins</td> <td></td>
                <td>2</td>
              </tr>
              <tr>
                <td>1-2mins</td> <td></td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default OfkAtHome

export const query = graphql`
  query {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
